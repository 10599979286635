<template>
    <div class="app">
        <div v-if="!token" class="auth pt-4 pb-4">
			<router-link to="/register" class="button secondary">Register</router-link>
			<router-link to="/login" class="button">Login</router-link>
		</div>

        <template v-if="token">
            <header>
                <a @click.prevent="$router.go(-1)" href="" class="back"><i class="fad fa-angle-left"></i></a>

                <p class="title">Admin</p>
            </header>


            <div class="container pt-2">
                <p v-for="message in messages.messages" :key="message.id" :class="['mb-2', message.user_id == me.id ? 'my' : 'you']">{{ message.message }}</p>
            </div>

            <div class="foating-form">
                <textarea type="text" class="message" v-model="formData.message" placeholder="Tulis pesan..."></textarea>
                <button type="button" class="send" @click="sendChat"><i class="fad fa-paper-plane"></i></button>
            </div>
        </template>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            token: localStorage.getItem('token'),
            me: '',
            messages: '',
            formData: {
                message: ''
            }
        }
    },
    methods: {
        getMe() {
            axios.get(`auth/me?token=${this.token}`)
                .then(res => {
                    this.me = res.data;
                })
                .catch(err => {
                    console.log(err.response.data);
                }); 
        },
        getMessage() {
            axios.get(`chat?token=${this.token}`)
                .then(res => {
                    this.messages = res.data;
                })
                .catch(err => {
                    console.log(err.response.data);
                }); 
        },
        sendChat() {
            axios.post(`chat?token=${this.token}`, this.formData)
                .then(res => {
                    this.getMessage();

                    this.formData.message = '';
                })
                .catch(err => {
                    console.log(err.response.data);
                }); 
        }
    },
    created() {
        if (this.token) this.getMe();
        if (this.token) this.getMessage();
    },
}
</script>

<style scoped>

.app {
    height: 100%;
    background-color: rgb(233, 233, 233);
}
    
.foating-form {
    position: fixed;
    bottom: 0;
    display: flex;
    left: 50%;
    transform: translateX(-50%);
    align-items: center;
    justify-content: space-between;
    height: 60px;
    width: 375px;
    background-color: white;
    padding: 0 1rem;
}

.send {
    height: 100%;
    width: 50px;
    background-color: white;
    border: none;
    color: rgb(184, 184, 184);
    font-size: 1.5rem;
}

.message {
    width: 85%;
    height: 35px;
    outline: none;
    padding: .5em 1rem;
    border-radius: 100px;
    border: none;
    box-shadow: 0 0 20px rgba(0, 0, 0, .05);
    background-color: rgb(240, 240, 240);
    font-size: 14px;
    resize: none;
}

.container {
    display: flex;
    flex-direction: column;
}

.my {
    align-self: flex-end;
    padding: .5em 1em;
    border-radius: 10px;
    width: fit-content;
    background-color: white;
    max-width: 80%;
}

.you {
    max-width: 80%;
    align-self: flex-start;
    padding: .5em 1em;
    border-radius: 10px;
    width: fit-content;
    color: white;
    background-color: var(--primary);
}

</style>